"use client";
import React, { useCallback } from "react";
import { SegmentSelectorProps } from "./types";
import Flicking from "@egjs/react-flicking";

export const SegmentSelector: React.FC<SegmentSelectorProps> = React.memo(
	({ segments, selectedSegment, onSegmentChange }) => {
		const handleSegmentChange = useCallback((segmentType: string) => {
			onSegmentChange(segmentType);
		}, []);

		return (
			<div className="mb-4 md:mb-8">
				<Flicking
					align="prev"
					gap={10}
					circular={false}
					bound={true}
					onSelect={(e: any) => {
						if (e.index !== undefined && segments[e.index]) {
							handleSegmentChange(segments[e.index].segment_type);
						}
					}}
				>
					{segments.map((segment) => (
						<button
							key={segment.segment_type}
							className={`px-3 py-1 md:py-1.5 rounded text-sm transition-colors mr-3 ${
								selectedSegment === segment.segment_type
									? "bg-green7 text-white"
									: "bg-grey0 border-grey1 border text-grey4 hover:bg-grey2 hover:border-grey2"
							}`}
						>
							<span className="leading-none align-text-bottom">
								{segment.segment_name}
							</span>
						</button>
					))}
				</Flicking>
			</div>
		);
	}
);

SegmentSelector.displayName = "SegmentSelector";
