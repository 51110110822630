"use client";
import React, { useState, useEffect, useCallback } from "react";
import { SegmentContent } from "./partials/SegmentContent";
import { SegmentSelector } from "./partials/SegmentSelector";
import { CookBookProps, SegmentContentType } from "./partials/types";
import { cookbookTitle, StickyBannerContent } from "./partials/text";
import { StickyBanner } from "../StickyBanner";
import { getCookbookData } from "../../utils/siteData";

const CookBook: React.FC<CookBookProps> = ({ data, pageArgs, mobileView }) => {
	const { segments, recipes } = data;
	const lang = pageArgs.locale.language || "nl";

	const [activeSegment, setActiveSegment] = useState<string>(
		segments[0]?.segment_type || ""
	);
	const [currentRecipes, setCurrentRecipes] = useState<SegmentContentType>(
		recipes[activeSegment].slice(0, 12)
	);
	const [seeMoreBtn, setSeeMoreBtn] = useState<boolean>(true);

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const tab = searchParams.get("tab");
		if (tab) {
			setActiveSegment(tab);
		}
	}, []);

	useEffect(() => {
		setCurrentRecipes(recipes[activeSegment].slice(0, 12));
		setSeeMoreBtn(true);
	}, [activeSegment, recipes]);

	const handleSegmentChange = useCallback((segmentType: string) => {
		setActiveSegment(segmentType);
		const url = new URL(window.location.href);
		url.searchParams.set("tab", segmentType);
		window.history.replaceState({}, "", url.href);
	}, []);

	const handleLoadMore = useCallback(() => {
		setCurrentRecipes(recipes[activeSegment]);
		setSeeMoreBtn(false);
	}, [activeSegment]);

	return (
		<div className="md:mb-[-32px]">
			<h1>{cookbookTitle[lang]}</h1>
			<SegmentSelector
				segments={segments}
				selectedSegment={activeSegment}
				onSegmentChange={handleSegmentChange}
			/>
			<SegmentContent
				recipes={currentRecipes}
				lang={lang}
				onLoadMore={handleLoadMore}
				loadMoreBtn={
					seeMoreBtn && activeSegment !== "THIS_WEEK_RECIPES"
				}
			/>
			<div className="my-4"></div>
			<StickyBanner
				content={StickyBannerContent}
				lang={lang}
				mobileView={mobileView}
				margins={true}
			/>
		</div>
	);
};

export default React.memo(CookBook);
