export const cookbookTitle = {
  nl: 'Koken Met Picnic',
  de: 'Kochen Mit Picnic',
  en: 'Cooking With Picnic',
  fr: 'Cuisiner Avec Picnic',
};

export const seeMore = {
  nl: 'Bekijk meer',
  de: 'Mehr anzeigen',
  en: 'See More',
  fr: 'Voir plus',
};

export const loading = {
  nl: ' Laden...',
  de: 'Laden...',
  en: 'Loading...',
  fr: 'Chargement...',
};

export const activePrepTime = {
  nl: 'min bereiden',
  de: 'min Vorbereiten',
  en: 'min Preparation',
  fr: 'min Préparation',
};

export const totalPrepTime = {
  nl: 'min totaal',
  de: 'min gesamt',
  en: 'min total',
  fr: 'min total',
};

export const StickyBannerContent = {
	title: {
		nl: "Zit er iets lekkers tussen?",
		de: "Gibt es etwas Leckeres?",
		fr: "Quelque chose de bon ?",
		en: "Saw something tasty?",
	},
	subtitle: {
		nl: "Laat alle ingrediënten vers bezorgen!",
		de: "Lass dir alles frisch liefern!",
		fr: "Livraison gratuite de vos recettes !",
		en: "Check out the entire catalogue!",
	},
	button: {
		text: {
			desktop: {
				nl: "Download onze app",
				de: "Hol dir unsere App",
				fr: "Télécharger l’appli",
				en: "Download our app",
			},
			mobile: {
				nl: "Kijk",
				de: "Zuur app",
				fr: "Voir",
				en: "View",
			},
		},
		link: {
			nl: "/nl/app-downloaden",
			de: "/de/app-herunterladen",
			fr: "/fr/telecharger-app",
			en: "/",
		},
	},
};
