"use client";
import React, { useState, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import { Langs, Recipe, SegmentContentType } from "./types";
import { activePrepTime, totalPrepTime, seeMore, loading } from "./text";

const RecipeCard = React.memo<{ recipe: Recipe; lang: Langs }>(
	({ recipe, lang }) => (
		<Link href={`recipes/${recipe.id}`} className="bg-white">
			{recipe.image && (
				<div className="relative h-auto aspect-square rounded md:rounded-lg overflow-hidden ">
					{recipe.display_label && (
						<div
							className={`absolute z-10 rounded px-1.5 py-0.5 top-2 left-2 text-sm bg-${
								recipe.display_label.background_color.toLowerCase() ||
								"green1"
							}`}
						>
							<span
								className={`leading-tight align-bottom text-${
									recipe.display_label.text_color.toLowerCase() ||
									"white"
								}`}
							>
								{recipe.display_label.text}
							</span>
						</div>
					)}
					<Image
						src={recipe.image}
						alt={recipe.name}
						layout="fill"
						objectFit="cover"
					/>
				</div>
			)}
			<div className="px-2 md:px-3 py-2 md:py-2.5 text-base">
				<p className="text-base text-green7 mb-0.5 leading-tight">
					{recipe.quality_cue}
				</p>
				<h4 className="text-lg leading-tight text-grey5 text-balance m-0">
					{recipe.name}
				</h4>
				{recipe.preparation_time_in_minutes && (
					<p className="text-base text-grey3 mt-0.5">
						{recipe.active_preparation_time_in_minutes &&
						recipe.preparation_time_in_minutes !==
							recipe.active_preparation_time_in_minutes
							? `${recipe.active_preparation_time_in_minutes} ${
									activePrepTime[
										lang as keyof typeof activePrepTime
									]
							  } | ${recipe.preparation_time_in_minutes} ${
									totalPrepTime[
										lang as keyof typeof totalPrepTime
									]
							  }`
							: `${recipe.preparation_time_in_minutes} min`}
					</p>
				)}
			</div>
		</Link>
	)
);

const SkeletonCard = React.memo(() => (
	<div className="bg-white">
		<div className="relative h-auto aspect-square rounded-lg overflow-hidden">
			<div className="w-full h-full bg-grey1 animate-pulse"></div>
		</div>
		<div className="px-2 md:px-3 py-2 md:py-2.5 text-base">
			<div className="w-1/2 h-4 bg-grey1 animate-pulse mt-0.5 rounded-full"></div>
			<div className="w-3/4 h-5 bg-grey1 animate-pulse mt-2 rounded-full"></div>
			<div className="w-1/4 h-4 bg-grey1 animate-pulse mt-2 rounded-full"></div>
		</div>
	</div>
));

export const SegmentContent: React.FC<{
	recipes: SegmentContentType;
	lang: Langs;
	onLoadMore: () => void;
	loadMoreBtn: boolean;
}> = React.memo(({ recipes, lang, onLoadMore, loadMoreBtn }) => {
	const [loadState, setLoadState] = useState(false);
	const [recipesDisplayed, setRecipesDisplayed] = useState(recipes);

	useEffect(() => {
		setLoadState(false);
		setRecipesDisplayed(recipes);
	}, [recipes]);

	const handleLoadMore = async () => {
		setLoadState(true);
		onLoadMore();
	};

	const SkeletonLoad = () => (
		<>
			{[...Array(6)].map((_, index) => (
				<SkeletonCard key={index} />
			))}
		</>
	);

	const LoadMoreButton: React.FC = () => (
		<button
			className={`bg-green1 text-white px-6 py-3 rounded-full mt-4 transition-opacity duration-300 ${
				loadState ? "opacity-50 pointer-events-none" : ""
			}`}
			onClick={handleLoadMore}
			disabled={loadState}
		>
			<span className="leading-tight align-middle">
				{loadState ? loading[lang] : seeMore[lang]}
			</span>
		</button>
	);

	return (
		<div className="flex flex-col">
			<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
				{recipesDisplayed.map((recipe) => (
					<RecipeCard key={recipe.id} recipe={recipe} lang={lang} />
				))}
				{loadState && <SkeletonLoad />}
			</div>
			{loadMoreBtn && (
				<div className="mt-4 flex justify-center">
					<LoadMoreButton />
				</div>
			)}
		</div>
	);
});

SegmentContent.displayName = "SegmentContent";
