"use client";
import React, { useState, useEffect } from "react";
import FormRenderer from "./partials/FormRenderer";
import { AllData } from "../../interfaces/blocks";
import { Form } from "./form.interfaces";
import { Card } from "@mui/material";
import { sendGTMEvent } from "@next/third-parties/google";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import WhatsAppApply from "../../../sites/jobs/components/WhatsAppApply";

type formProps = AllData<Form>;

const RenderFormComponent = ({
	data,
	mobileView,
	pageArgs,
	context,
}: formProps) => {
	const formList = context?.forms?.data || [];
	console.log(data);
	const baseSettings = data?.settings || {};
	const [formData, setFormData] = useState<Form | null>(data);
	const [formPulled, setFormPulled] = useState<boolean>(false);
	if (data.form_id && !formPulled) {
		const form: Form | undefined = formList.find(
			(i) => i.form_id === data.form_id
		);
		if (form) {
			setFormData(form);
			setFormPulled(true);
		}
	}

	const formId = data?.form_id || "";

	const theme = createTheme({
		components: {
			MuiInputLabel: {
				styleOverrides: {
					asterisk: {
						display:
							data.styling?.required_asterisk === false
								? "none"
								: "inline",
					},
				},
			},
		},
	});

	useEffect(() => {
		// TODO: Also define this in the form instead
		let market = "nl";
		if (formId.includes("-fr") || formId.includes("fr-")) {
			market = "fr";
		} else if (formId.includes("-de") || formId.includes("de-")) {
			market = "de";
		}

		// TODO: Have this defined within forms instead of this weird logic
		let category = "runner";
		if (formId.includes("shopper")) {
			category = "shopper";
		}
		if (formId.includes("supervisor")) {
			category = "supervisor";
		}
		if (formId.includes("recruiter")) {
			category = "recruiter";
		}
		if (formId.includes("cs-")) {
			category = "cs";
		}
		// This is a good discriminator between gh forms and manual ones
		if(formId == "" || !formId) {
			category = "hq";
		}

		const formSettings = form_id ? formData?.settings : settings;
		const utmData = {
			job_id: formSettings?.job_id,
			...(formSettings?.source == "fountain" && {
				utm_source: pageArgs.query.utm_source || "",
				utm_medium: pageArgs.query.utm_medium || "",
				utm_campaign: pageArgs.query.utm_campaign || "",
				utm_content: pageArgs.query.utm_content || "",
				utm_term: pageArgs.query.utm_term || "",
			}),
			...(formSettings?.source == "greenhouse" && {
				mapped_url_token: pageArgs.query.gh_src || "",
			}),
			jobs_form_market: market,
			jobs_form_category: category,
			jobs_form_job_id: formSettings?.job_id,
			utm_source: pageArgs.query.utm_source || "",
			utm_medium: pageArgs.query.utm_medium || "",
			utm_campaign: pageArgs.query.utm_campaign || "",
			utm_content: pageArgs.query.utm_content || "",
			utm_term: pageArgs.query.utm_term || "",
			bg_source: pageArgs.query.bg_source || "",
			bg_campaign: pageArgs.query.bg_campaign || "",
			bg_kw: pageArgs.query.bg_kw || "",
			bg_source_id: pageArgs.query.bg_source_id || "",
			gad_source: pageArgs.query.gad_source || "",
			jobs_form_gclid: pageArgs.query.gclid || "",
			jobs_form_clid: pageArgs.query.clid || "",
			jobs_form_cid: pageArgs.query.cid || "",
			pageCategory: [
				"job-text",
				pageArgs.locale.language,
				pageArgs?.extra?.job?.data?.name ? pageArgs?.extra?.job?.data?.name?.replace(/\W+/g, "-").toLowerCase() : "",
			], // Example categories
		};
		sendGTMEvent(utmData);
	}, []);

	if (!formData) {
		return null; // You might want to render a loading state or handle this case differently
	}

	let { form_id, name, steps, settings, styling } = formData;
	settings = {...baseSettings, ...settings || {}};
	return (
		<ThemeProvider theme={theme}>
			<Card
				sx={{ position: "relative" }}
				elevation={0}
				className={`${
					styling?.variant === "grid" ? "md:max-w-[52rem]" : "md:w-96"
				} z-50 mx-auto w-full !rounded p-5 drop-shadow-faded text-center backdrop-blur-0`}
			>
				<a id="application-form"></a>
				<FormRenderer
					mobileView={mobileView}
					theme={theme}
					formId={data?.form_id || ""}
					name={form_id ? formData?.name : name}
					steps={form_id ? formData?.steps : steps}
					variant={styling?.variant}
					settings={form_id ? formData?.settings : settings}
					context={context}
					args={pageArgs}
				/>
				<WhatsAppApply pageArgs={pageArgs} enabled={settings?.whatsapp_apply} />
			</Card>
		</ThemeProvider>
	);
};

export default RenderFormComponent;
