"use client";
import React, { useRef } from "react";
import IngredientsAndInstructions from "./partials/IngredientsAndInstructions";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useScrollResize, useContentScroll } from "./partials/scroll";
import { formatMarkdown } from "./partials/utils";
import {
	activePrepTime,
	totalPrepTime,
	StickyBannerContent,
	BackToCookbook,
} from "./partials/text";
import { StickyBanner } from "../StickyBanner";
import Cta from "./partials/Cta";
import Link from "next/link";

type RecipeProps = {
	data: any;
	mobileView: boolean;
	context?: any;
	pageArgs?: any;
};

const RenderRecipe = ({ data, pageArgs, mobileView }: RecipeProps) => {
	const contentRef = useRef(null);
	const containerRef = useRef(null);
	useContentScroll(contentRef, containerRef);

	const recipe = data;
	const lang = pageArgs.locale.language || "nl";

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const newPath = `/recipes/${recipe?.id}/${slugify(recipe?.name ?? "")}?${queryParams.toString()}`;
		window.history.replaceState(null, "", newPath);
	}, [recipe]);

	const RecipeImage = () => {
		const imageUrl = recipe.images[0];

		const initialHeight = 500;
		const minHeight = 416;
		const imageHeight = useScrollResize(initialHeight, minHeight);
		const imageStyle = mobileView
			? {
					backgroundImage: `url("${imageUrl}")`,
					height: `500px`,
					backgroundSize: `auto ${imageHeight}px`,
			  }
			: {
					backgroundImage: `url("${imageUrl}")`,
			  };
		return (
			<div className={"md:w-[55%]"}>
				<div
					className={`inset-0 w-full h-full bg-cover
				    ${mobileView && window.scrollY > 88 ? "bg-bottom" : "bg-center"}`}
					style={imageStyle}
				></div>
			</div>
		);
	};

	const RecipeTime = () => {
		const activeTime = recipe.active_preparation_time_in_minutes;
		const totalTime = recipe.preparation_time_in_minutes;
		return (
			<div className={"flex gap-1 text-green1 align-middle mb-2"}>
				<AccessTimeIcon
					className={"my-auto"}
					style={{
						fontSize: 20,
					}}
				/>
				<span>
					<small>
						{activeTime && totalTime !== activeTime
							? `${activeTime} ${
									activePrepTime[
										lang as keyof typeof activePrepTime
									]
							  } • ${totalTime} ${
									totalPrepTime[
										lang as keyof typeof totalPrepTime
									]
							  }`
							: `${totalTime} min`}
					</small>
				</span>
			</div>
		);
	};

	const BackButton = () => {
		const link = {
			de: "/de/recipes",
			fr: "/fr/recipes",
			nl: "/nl/recipes",
		};
		return (
			<div className="mb-6 top-[105px] md:top-auto z-50 absolute md:relative">
				<Link href={link[lang]}>
					<div className="px-3 py-2 bg-grey1 rounded-full w-fit flex items-center gap-2">
						<img
							width={24}
							src={"/assets/general/img/arrow.svg"}
							alt={`Back arrow`}
						/>
						<div className="leading-none align-text-bottom mt-0.5 text-grey4 font-mediun">
							{BackToCookbook[lang]}
						</div>
					</div>
				</Link>
			</div>
		);
	};

	const RecipeContent = () => {
		return (
			<div
				ref={contentRef}
				className={
					"flex-1 bg-white rounded-xl md:rounded-none -mt-4 md:mt-0 z-50 overflow-hidden"
				}
			>
				<div className={"p-6 md:p-10 p-12 md:pb-20 text-pretty"}>
					<BackButton />
					<RecipeTime />
					<h2 className={"mb-2 md:mb-3"}>
						<strong>{recipe.name}</strong>
					</h2>
					<h5>{formatMarkdown(recipe.description)}</h5>
					<IngredientsAndInstructions
						ingredients={recipe.ingredients}
						instructions={recipe.preparation_instructions}
						lang={lang}
					/>
				</div>
			</div>
		);
	};

	if (recipe) {
		return (
			<>
				<div
					ref={containerRef}
					className="flex flex-col md:flex-row md:h-[815px] text-grey5 max-w-[2000px] h-auto m-auto"
				>
					<RecipeImage />
					<RecipeContent />
				</div>
				<StickyBanner
					content={StickyBannerContent}
					lang={lang}
					mobileView={mobileView}
				/>
				{!mobileView && <Cta lang={lang} />}
			</>
		);
	}
};

export default RenderRecipe;
