"use client";
import React, { useState, useRef } from "react";
import { WhatsApp } from "@mui/icons-material";
import { localeRouteInterface } from "@/utils";
import { translations } from "./partials/text";
import FlagPhoneInput from "./partials/phone-input";

type Props = {
	pageArgs: localeRouteInterface;
	enabled: boolean;
};

const WhatsAppForm = ({ pageArgs, enabled }: Props) => {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const buttonRef = useRef<HTMLButtonElement>(null);
	const [phoneNumber, setPhoneNumber] = useState("");
	const searchParams = new URLSearchParams(window.location.search);
	
	if(!enabled && !searchParams?.get("tests")?.includes("whatsapp")) {
		return null;
	}

	const getUtmParams = () => {
		return {
			source: searchParams.get("utm_source") || "",
			medium: searchParams.get("utm_medium") || "",
			campaign: searchParams.get("utm_campaign") || "",
		};
	};

	const getJobId = () => {
		const pathSegments = window.location.pathname.split("/");
		return (
			pathSegments
				.find(
					(segment) => segment.startsWith("J") && segment.length === 8
				)
				?.slice(1) || ""
		);
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);
		const form = e.currentTarget;
		const utmParams = getUtmParams();
		const jobId = getJobId();

		const body = {
			phoneNumber,
			jobId,
			fields: {
				utm_source: utmParams.source,
				utm_medium: utmParams.medium,
				utm_campaign: utmParams.campaign,
			},
		};
		if(phoneNumber.length < 5) {
			setIsLoading(false);
			return;
		}
		try {
			const response = await fetch("/api/forms/submission/whatsapp", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(body),
			});

			if (response.ok) {
				form.reset();
				setIsSubmitted(true);
			} else {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
		} catch (error) {
			console.error("Error:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.target.value = e.target.value.replace(/\D/g, "").slice(0, 14);
	};

	const handleButtonClick = () => {
		if (buttonRef.current) {
			buttonRef.current.style.transition =
				"width 0.5s ease-in-out, opacity 0.1s ease-out 0.250s";
			buttonRef.current.style.width = "0";
			buttonRef.current.style.padding = "0";
			buttonRef.current.style.opacity = "0";
			buttonRef.current.style.pointerEvents = "none";
		}
	};

	const lang = pageArgs?.locale?.language || "en";

	return (
		<div className="bg-white rounded-lg w-full mx-auto mt-2">
			<div className="relative">
				{!isSubmitted ? (
					<>
						<button
							ref={buttonRef}
							onClick={handleButtonClick}
							className="absolute right-0 flex h-full w-full bg-white underline py-2 px-4 rounded transition whitespace-nowrap overflow-hidden z-20"
						>
							<span className="flex items-center text-xs mx-auto text-grey4 hover:text-green6">
								<WhatsApp className="mr-2" />
								{translations.applyViaWhatsApp[lang]}
							</span>
						</button>
						<form onSubmit={handleSubmit} className="w-full z-10 flex">
							<div className="flex w-full">
								<div style={{ width: "65%" }}>
									<FlagPhoneInput
										value={phoneNumber}
										onChange={setPhoneNumber}
										placeholder="Enter your WhatsApp number"
										className="w-full max-w-xs sm:max-w-full px-3 py-2 rounded border border-gray-300 focus:outline-none focus:border-green-500 transition"
									/>
								</div>
								<button
									type="submit"
									id="whatsapp-submit"
									className={`bg-green6 text-white font-bold py-2 px-4 rounded-r hover:bg-green1 transition-all duration-300 ease-in-out`}
									disabled={isLoading}
									style={{ width: "35%" }}
								>
									{isLoading
										? translations.submitting[lang]
										: translations.submit[lang]}
								</button>
							</div>
						</form>
					</>
				) : (
					<div className="text-center text-green6 font-bold">
						{translations.thanksForApplying[lang]} <br />
						{translations.contactSoon[lang]}
					</div>
				)}
			</div>
		</div>
	);
};

export default WhatsAppForm;
