import React, { useEffect, useState } from "react";
import { mediaParseLogic } from "../../../utils/mediaParseLogic";
import { MediaProps } from "../media.interfaces";
import { HandleSizing } from "./HandleSizing";
import Image from "next/image";

export const RenderVideo = ({ data, mobileView }: MediaProps) => {
	const { media_src, styling } = data;
	const [isLoaded, setIsLoaded] = useState(false);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const [clicked, setClicked] = useState(false);
	const [hovered, setHovered] = useState(false);
	const [isMuted, setIsMuted] = useState(styling?.video?.muted ?? true);
	const [isControl, setIsControl] = useState(styling?.video?.controls);
	const videoRef = React.useRef(null);
	var sizingClasses = HandleSizing(styling?.media_sizing, mobileView);
	const mediaClass = `${sizingClasses.width} ${sizingClasses.height} ${
		styling?.variant === "padded" || styling?.rounded_corners
			? styling?.rounded_size ?? "rounded"
			: ""
	}`;
	const currentMedia = mediaParseLogic(mobileView, media_src);
	

	// Video parameters
	const isLoop = ["auto"].includes(styling?.video?.play_mode);
	const isAutoplay = ["auto", "auto-hover"].includes(
		styling?.video?.play_mode
	);

	useEffect(() => {
		const videoElement = videoRef.current;

		const handleFullscreenChange = () => {
			setIsFullscreen(
				!!(
					document.fullscreenElement &&
					document.fullscreenElement === videoElement
				)
			);
		};

		videoElement.addEventListener(
			"fullscreenchange",
			handleFullscreenChange
		);

		return () => {
			videoElement.removeEventListener(
				"fullscreenchange",
				handleFullscreenChange
			);
		};
	}, []);

	const handleMouseEnter = () => {
		setHovered(true);
		if (styling?.video?.play_mode === "hover" && videoRef.current) {
			videoRef.current.play();
		}
		if (
			styling?.video?.play_mode === "auto-hover" &&
			videoRef.current &&
			videoRef.current.currentTime >= videoRef.current.duration
		) {
			videoRef.current.play();
		}
		if(styling?.video?.play_mode == "normal") {
			setIsControl(true);
			setIsMuted(false);
		}
	};

	const handleMouseLeave = () => {
		setHovered(false);
		if (styling?.video?.play_mode === "hover" && videoRef.current) {
			videoRef.current.pause();
			videoRef.current.currentTime = 0;
		}
		if(styling?.video?.play_mode == "normal") {
			setIsMuted(true);
		}
	};

	return (
		<div
			className={`group relative w-full ${mediaClass}`}
			style={{
				backgroundImage: `url("${
					currentMedia?.meta ? currentMedia?.meta.blurhash : ""
				}")`,
				backgroundSize: "cover", // or 'contain' or other values
				backgroundPosition: "center", // adjust as needed
				backgroundRepeat: "no-repeat",
			}}
		>
			<video
				controls={isControl && (styling?.video?.play_mode != "normal" || hovered || clicked)}
				onClick={() => {
					setClicked(true);
				}}
				ref={videoRef}
				loop={isLoop}
				autoPlay={isAutoplay}
				muted={isMuted}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				playsInline={true}
				preload={"metadata"}
				className={`
					${mediaClass} 
					${
						styling?.video?.overlay &&
						"brightness-[0.6] hover:brightness-100 transition-all duration-300"
					} 
					${
						!isFullscreen &&
						(styling?.cover === undefined || styling?.cover === true) &&
						"object-cover"
					}
				`}
				onLoadedData={() => setIsLoaded(true)}
				onError={(e) => console.error("Video loading error:", e)}
				disablePictureInPicture
				disableRemotePlayback
			>
				<source src={currentMedia?.src} />
			</video>
			{styling?.video?.overlay && !clicked && (
				<Image
					className="absolute inset-0 m-auto object-cover opacity-60 group-hover:h-0 group-hover:opacity-0 transition-all duration-300"
					src="https://d2jxuf8ovdiw8x.cloudfront.net/assets/play-btn-1713940417235.webp"
					alt="Play video"
					width={42}
					height={42}
				/>
			)}
		</div>
	);
};
